import { onMounted } from 'vue';
import throttle from 'lodash.throttle';
import { ThrottleOptions } from '../useThrottle';

type Fn = (...args: any) => any;

function useThrottleFn<T extends Fn>(fn: T, options?: ThrottleOptions) {
  const wait = options?.wait ?? 1000;

  const throttled = throttle<T>(
    ((...args: any[]) => {
      return fn(...args);
    }) as T,
    wait,
    options,
  );

  onMounted(() => {
    throttled.cancel();
  });

  return {
    run: (throttled as unknown) as T,
    cancel: throttled.cancel,
    flush: throttled.flush,
  };
}

export { useThrottleFn };
