import { computed, onMounted, reactive, Ref, isRef } from 'vue';
import { useRequest } from '../useRequest';
import {
  BasePaginatedOptions,
  CombineService,
  PaginatedFormatReturn,
  PaginatedOptionsWithFormat,
  PaginatedParams,
  PaginatedResult,
} from './useRequestTypes';
import { config } from '../config';

export interface Result<Item> extends PaginatedResult<Item> {
  search: {
    // type: "simple" | "advance";
    // changeType: () => void;
    submit: () => void;
    reset: () => void;
  };
}
export interface Store {
  [name: string]: any;
}
export interface UseAntdTableFormUtils {
  // getFieldInstance?: (name: string) => Record<string, any>; // antd 3
  // setFieldsValue: (value: Store) => void;
  // getFieldsValue: (...args: any) => Store;
  resetFields: (...args: any) => void;
  validateFields: any;
  [key: string]: any;
}
export interface OptionsWithFormat<R, Item, U>
  extends Omit<PaginatedOptionsWithFormat<R, Item, U>, 'paginated'> {
  form?: Ref<any> | any;
  formRef?: Ref<UseAntdTableFormUtils>;
  // defaultType?: "simple" | "advance";
}
export interface BaseOptions<U>
  extends Omit<BasePaginatedOptions<U>, 'paginated'> {
  form?: Ref<any> | any;
  formRef?: Ref<any>;
  // defaultType?: "simple" | "advance";
}

function useAntdTable<R = any, Item = any, U extends Item = any, P = any>(
  service: CombineService<R, PaginatedParams<P>>,
  options?: OptionsWithFormat<R, Item, U>,
): Result<Item>;
function useAntdTable<R = any, Item = any, U extends Item = any, P = any>(
  service: CombineService<PaginatedFormatReturn<Item>, PaginatedParams<P>>,
  options?: BaseOptions<U>,
): Result<Item>;
function useAntdTable<R = any, Item = any, U extends Item = any>(
  service: CombineService<any, any>,
  options: BaseOptions<U> | OptionsWithFormat<R, Item, U> = {},
): any {
  // 读取配置
  const {
    useAntdTable: { defaultPageSize: _defaultPageSize, replacePageFields } = {},
  } = config;
  const {
    initialData = {
      list: [],
      total: 0,
    },
    manual = false,
    formRef,
    defaultPageSize = _defaultPageSize || 10,
    defaultParams,
    onError,
  } = options;

  // 表单数据
  const form = computed(() => {
    const { form } = options;
    if (form && isRef(form)) {
      return form.value;
    }
    return form;
  });

  // 将默认值设置到表单中
  if (form.value && defaultParams && defaultParams[1]) {
    const defaultFormData = defaultParams[1];
    Object.keys(defaultFormData).forEach(key => {
      const value = defaultFormData[key];
      form.value[key] = value;
    });
  }

  // 分页参数
  const pageParams = reactive(
    defaultParams && defaultParams[0]
      ? defaultParams[0]
      : {
          current: 1,
          pageSize: defaultPageSize,
        },
  );

  const { data, loading, run, refresh } = useRequest(service, {
    initialData,
    manual: true,
    onError,
  });

  const _run = (pageParams, formData) => {
    const _pageParams: Record<string, any> = {};
    if (replacePageFields) {
      // 替换分页参数
      Object.keys(pageParams).forEach(key => {
        _pageParams[replacePageFields[key] || key] = pageParams[key];
      });
    }
    run(_pageParams, formData);
  };

  // 执行接口
  onMounted(() => {
    if (!manual) {
      _run(pageParams, form.value);
    }
  });

  // 分页参数
  const pagination = computed(() => {
    const { total } = data.value;
    return {
      current: pageParams.current,
      pageSize: pageParams.pageSize,
      total,
      totalPage: Math.ceil(total / pageParams.pageSize),
      defaultPageSize:
        defaultParams && defaultParams[0]
          ? defaultParams[0].pageSize
          : defaultPageSize,
      onChange: (current: number, pageSize: number) => {
        pageParams.current = current;
        pageParams.pageSize = pageSize;
        _run(pageParams, form.value);
      },
      changeCurrent: (current: number) => {
        pageParams.current = current;
        _run(pageParams, form.value);
      },
      changePageSize: (pageSize: number) => {
        pageParams.pageSize = pageSize;
        _run(pageParams, form.value);
      },
    };
  });

  const tableProps = computed(() => {
    return {
      dataSource: data.value.list || [],
      loading: loading.value,
      onChange: (pagination, filters, sorter) => {
        console.log('tableProps.onChange', pagination, filters, sorter);
      },
      pagination: pagination.value,
    };
  });

  const _submit = () => {
    setTimeout(() => {
      pageParams.current = 1;
      _run(pageParams, form.value);
    }, 0);
  };

  const search = {
    submit: _submit,
    run: _run,
    reset: () => {
      formRef?.value?.resetFields();
      _submit();
    },
  };

  return {
    refresh,
    loading,
    run: _run,
    search,
    pagination,
    tableProps,
    // sorter,
    // filters
  };
}

export { useAntdTable };
