interface Config {
  useCountDown?: {
    dateFn?: any;
  };
  useRequest?: {
    throwOnError?: boolean;
  };
  useAntdTable?: {
    defaultPageSize?: number;
    replacePageFields?: {
      current?: string;
      pageSize?: string;
    };
  };
}

export const config = {} as Config;

export const init = (cfg: Config) => {
  Object.keys(cfg).forEach(key => {
    config[key] = cfg[key];
  });
};
