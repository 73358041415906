import { ref } from 'vue';

export interface Options {
  beforeOpen?: (...args: any[]) => any;
  afterOpen?: (...args: any[]) => any;
  beforeClose?: (...args: any[]) => any;
  afterClose?: (...args: any[]) => any;
  manualOpen?: boolean;
  manualClose?: boolean;
}

const useModalState = (options: Options = {}) => {
  const {
    beforeOpen,
    afterOpen,
    beforeClose,
    afterClose,
    manualOpen = false,
    manualClose = false,
  } = options;
  const visible = ref(false);

  const handleOpen = async (...args) => {
    await beforeOpen?.(...args);
    // 如果手动
    if (!manualOpen) {
      visible.value = true;
    }
    await afterOpen?.(...args);
  };

  const handleClose = async (...args) => {
    await beforeClose?.(...args);
    if (!manualClose) {
      visible.value = false;
    }
    await afterClose?.(...args);
  };

  return {
    visible,
    handleOpen,
    handleClose,
  };
};

export { useModalState };
