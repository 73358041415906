import { onMounted } from 'vue';
import debounce from 'lodash.debounce';
import { DebounceOptions } from '../useDebounce';

type Fn = (...args: any) => any;

function useDebounceFn<T extends Fn>(fn: T, options?: DebounceOptions) {
  const wait = options?.wait ?? 1000;

  const debounced = debounce<T>(
    ((...args: any[]) => {
      return fn(...args);
    }) as T,
    wait,
    options,
  );

  onMounted(() => {
    debounced.cancel();
  });

  return {
    run: (debounced as unknown) as T,
    cancel: debounced.cancel,
    flush: debounced.flush,
  };
}

export { useDebounceFn };
